import { when } from '../../lib/when'
import type { AnalyticsCollector } from '../collector'

export function initDrift(ko: AnalyticsCollector) {
  const condition = () => typeof window.drift !== 'undefined' && typeof window.drift.on === 'function'

  const wrapped = (method: string, fn: () => void) => {
    try {
      fn()
    } catch (error) {
      ko?.trackError(error as Error, `drift.${method}`)
    }
  }

  const callback = () => {
    if (!condition()) return

    window.drift.on('ready', () => {
      window.drift.on('emailCapture', (event: any) => {
        wrapped('emailCapture', () => {
          if (ko && !ko.email && event && event.data && event.data.email) {
            ko.identify({ email: event.data.email }, { source: 'drift' })
            ko.track('Drift Email Captured', event.data)
          }
        })
      })

      window.drift.on('phoneCapture', (data: any) => {
        wrapped('phoneCapture', () => {
          if (ko && data && data.phone) {
            ko.identify({ phone: data.phone }, { source: 'drift' })
            ko.track('Drift Phone Captured', data)
          }
        })
      })

      window.drift.on('startConversation', (data: any) => {
        wrapped('startConversation', () => {
          if (ko && data) {
            const traits: Record<string, string> = {}

            if (data.endUserEmail) {
              traits.email = data.endUserEmail
            }
            if (data.endUserId) {
              traits.drift__contact_id = data.endUserId
            }

            if (!ko.email) {
              ko.identify(traits, { source: 'drift' })
            }
            ko.track('Drift Conversation Started', data)
          }
        })
      })

      window.drift.on('scheduling:meetingBooked', (data: any) => {
        wrapped('scheduling:meetingBooked', () => {
          if (ko && data) {
            ko.track('Drift Meeting Booked', data)
          }
        })
      })
    })
  }

  when(condition, callback, { timeout: 500, retries: 10 })
}
