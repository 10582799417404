import type { Consumer, Mixin, Subscription } from '@rails/actioncable'
import { AnalyticsCollector } from '../analytics/collector'

type OnReceivedFn = (data: any) => void

export type ProfileSubscription = Subscription &
  Mixin & {
    connected: () => void
    disconnected: () => void
    received: OnReceivedFn
    rejected: () => void
    updatePresence: (lastActivity?: string) => void
  }

export function createProfileSubscription(
  client: Consumer,
  instance: AnalyticsCollector,
  onReceived: OnReceivedFn
): ProfileSubscription {
  return client.subscriptions.create(
    { channel: 'ProfileChannel', sid: instance.session.id },
    {
      isConnected: false,
      initialized() {
        this.updatePresence = this.updatePresence.bind(this)
      },
      connected() {
        this.isConnected = true
      },
      disconnected() {
        this.isConnected = false
      },
      rejected() {
        this.isConnected = false
      },
      updatePresence(lastActivityAt?: string) {
        if (!this.isConnected || !lastActivityAt) return
        this.perform('presence', {
          sid: instance.session.id,
          last_activity_at: lastActivityAt,
          visible: document.visibilityState === 'visible'
        })
      },
      received: onReceived
    }
  )
}
