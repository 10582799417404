import { when } from '../../lib/when'
import type { AnalyticsCollector } from '../collector'

export function initQualified(ko: AnalyticsCollector) {
  const condition = () => typeof window.qualified === 'function'

  const callback = () => {
    if (!condition()) return

    window.qualified('handleEvents', (name: string, data: any) => {
      try {
        if (!ko) return

        if (data && data.field_values && !ko.email) {
          const email = data.field_values.email
          if (email) {
            ko.identify({ email }, { source: 'qualified' })
          }
        }

        ko.track('Qualified ' + name, data)
      } catch (error) {
        ko?.trackError(error as Error, 'qualified.handleEvents')
      }
    })
  }

  when(condition, callback, { timeout: 100, retries: 10 })
}
