import { v4 as uuid } from '@lukeed/uuid'
import * as api from '../api/collect'
import { Context, EventContext } from './event-context'
import { Queue, QueueOptions } from './queue'

export type Event = {
  context: Context
  message_id: string
  type: string
  event: string
  properties?: Record<string, unknown>
  sent_at?: string
}

export class EventQueue extends Queue<Event> {
  projectSlug: string
  events: Event[] = []
  context: EventContext

  constructor(options: QueueOptions<Event> = {}, projectSlug: string, ctx: EventContext) {
    super(options)
    this.projectSlug = projectSlug
    this.context = ctx
  }

  track(event: string, properties: { [key: string]: unknown } = {}, type = 'track') {
    const e: Event = {
      context: this.context.current('event'),
      message_id: uuid(),
      type: type,
      event,
      properties: properties as Record<string, unknown>,
      sent_at: new Date().toISOString()
    }
    this.events.push(e)
    this.add(e)
  }

  reset() {
    this.queue.clear()
    this.flushed.length = 0
    this.events = []
  }

  // Override the default onSend method to send the events to the server
  protected onSend(events: Event[] = []) {
    const profile_id = this.context.user.id()
    const project = this.projectSlug

    if (!project || !profile_id) {
      return false
    }

    return api.collectEvents(project, profile_id, events)
  }
}
