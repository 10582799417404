import { when } from '../../lib/when'
import type { AnalyticsCollector } from '../collector'

const navattic_events: Record<string, string> = {
  VIEW_STEP: 'Step Viewed',
  START_FLOW: 'Flow Started',
  COMPLETE_FLOW: 'Flow Completed',
  START_CHECKLIST: 'Checklist Started',
  OPEN_CHECKLIST: 'Checklist Opened',
  CLOSE_CHECKLIST: 'Checklist Closed',
  COMPLETE_TASK: 'Task Completed',
  CONVERTED: 'User Converted',
  NAVIGATE: 'User Navigated',
  IDENTIFY_USER: 'User Identified',
  ENGAGE: 'User Engaged'
}

interface NavatticEvent {
  type: string
  [key: string]: any
}

export function initNavattic(ko: AnalyticsCollector) {
  const condition = () => typeof window.navattic !== 'undefined' && typeof window.navattic.onEvent === 'function'

  const callback = () => {
    window.navattic.onEvent((event: NavatticEvent) => {
      if (!ko) return

      try {
        if (event.type === 'IDENTIFY_USER' && event.attributes && !ko.email) {
          const email = event.attributes['user.email'] || event.attributes['email']
          if (email) {
            ko.identify({ email }, { source: 'navattic' })
          }
        }

        if (event.type) {
          ko.track('Navattic ' + (navattic_events[event.type] || event.type), event)
        }
      } catch (error) {
        ko?.trackError(error as Error, 'navattic.onEvent')
      }
    })
  }

  when(condition, callback, { timeout: 500, retries: 20 })
}
