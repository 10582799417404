import Cookies, { CookieAttributes } from 'js-cookie'
import { v4 as uuid } from '@lukeed/uuid'
import { isEqual } from 'es-toolkit'
import { BootstrapData } from '../api/bootstrap'
import { allowedCookieDomain, removeCookie, setCookie } from './cookies'
import { topDomain } from './top-domain'

const ANON_KEY = 'ko_id'
const TRAITS_KEY = 'kl:traits'

let domain: string | undefined = undefined
try {
  domain = topDomain(new URL(window.location.href))
} catch (_) {
  domain = undefined
}

export interface Traits {
  referrer?: string
  utmParams?: Record<string, string>
  email?: string
  utk?: string
}

export interface User {
  id?: string | null
  traits: Traits
}

export interface UserStoreOptions {
  cookies?: CookieAttributes
}

export function getUserId() {
  return window.localStorage.getItem(ANON_KEY) || Cookies.get(ANON_KEY)
}

export class UserStore {
  cookieDefaults: CookieAttributes = {
    expires: 365, // one year
    domain,
    path: '/',
    sameSite: 'lax'
  }

  constructor(options?: UserStoreOptions) {
    this.cookieDefaults = {
      ...this.cookieDefaults,
      ...options?.cookies
    }
  }

  // gets or sets a uuid
  id() {
    const id = getUserId()

    if (id) {
      return id
    }

    return this.setId(uuid())
  }

  setId(id: string) {
    // do not set cookie if the domain is not at least the same top level domain
    if (allowedCookieDomain(domain, this.cookieDefaults.domain)) {
      setCookie(ANON_KEY, id, this.cookieDefaults)
    } else {
      removeCookie(ANON_KEY)
    }

    window.localStorage.setItem(ANON_KEY, id)
    return id
  }

  email() {
    return this.traits().email
  }

  traits(): Traits {
    try {
      return JSON.parse(window.localStorage.getItem(TRAITS_KEY) || '{}')
    } catch (_err) {
      return {}
    }
  }

  upsertTraits(toUpsert: Traits): Traits {
    const existing = this.traits()

    const newTraits = {
      ...existing,
      ...toUpsert
    }

    window.localStorage.setItem(TRAITS_KEY, JSON.stringify(newTraits))
    return newTraits
  }

  netNewTraits(toDiff: Traits) {
    const existingTraits = this.traits() as Record<string, unknown>
    const incomingTraits = { ...toDiff } as Record<string, unknown>

    Object.keys(incomingTraits).forEach((key) => {
      if (isEqual(existingTraits[key], incomingTraits[key])) {
        delete incomingTraits[key]
      }
    })

    return incomingTraits
  }

  userInfo(): User {
    return {
      id: this.id(),
      traits: this.traits()
    }
  }

  reset() {
    window.localStorage.removeItem(ANON_KEY)
    window.localStorage.removeItem(TRAITS_KEY)
    removeCookie(ANON_KEY)
  }
}

export const user = (options?: BootstrapData) =>
  new UserStore({
    cookies: options?.sdk_settings?.cookie_defaults
  })
