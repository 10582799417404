import type { AnalyticsCollector } from '../collector'

export function initHubSpot(ko: AnalyticsCollector) {
  // hook into hubspot form submission and meeting bookings
  window.addEventListener('message', (event) => {
    if (!ko || ko.email || !event.data) return false

    try {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
        const email = event.data.data?.submissionValues?.email
        if (email) {
          ko.identify({ email }, { source: 'hubspot_form' })
        }
      } else if (event.data.meetingBookSucceeded) {
        const booking = event.data.meetingsPayload?.bookingResponse || {}
        const contact = booking.postResponse?.contact
        const email = contact?.email
        if (email) {
          ko.identify({ email }, { source: 'hubspot_meeting' })
        }
        ko.track('Meeting Booked', {
          integration: 'HubSpot',
          contact,
          organizer: booking.postResponse?.organizer,
          meeting_date: booking.event?.dateString
        })
      }
    } catch (error) {
      ko?.trackError(error as Error, 'initHubSpot')
    }
  })
}
